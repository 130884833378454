import React from 'react'
import Layout from '../../components/layout/layout'
import Header from '../../components/header/header'
import Nav from '../../components/nav/nav'
import Footer from '../../components/footer/footer'
import styles from './bio.module.css'

export default () => (
  <Layout>
    <Header />
    <Nav />
    <main style={{ display: 'flex', justifyContent: 'center' }}>
      <div className={styles.bio}>
        <p>
          B. in Buenos Aires, Argentina. Currently lives in the U.S. Folta is an interdisciplinary artist, an independent curator and advocate of native plants.
        </p>
        <p>
          After leaving her country in times of military repression, she had the great opportunity to get involved in multicultural environments living in different parts of the world: Puerto Rico, through the United States and Europe. She applies this wide range of influences to her works.
        </p>
        <p>
          Lately, she has been working on environmental topics, such as glaciers, coral reefs and most recently, the biodiversity crisis. She researches and practices sustainable gardening by planting native plants and helping people to understand its importance.
        </p>
        <p>
          From 2009 to 2015, Folta lived at Midway Artist Studios in Fort Point, the oldest artist community in Boston, where she curated exhibitions in collaboration with artists in the building for five years.
        </p>
        <p>
          From 2015 to 2019, she moved to Berlin, Germany where she presented her environmental and social-political projects in international cultural platforms, such as Berlin Science Week at Humboldt University - Thaer Institute; François Schneider Foundation Contemporary Art Centre, France; Art in the Gerichtshöfen, Berlin Germany; Group Global 3000 Sustainable Art Gallery for the Berlin Art Week.
        </p>
        <p>
          She was a guest artist at the 1st. Biennial of the Americas at Museum of the Americas, Denver Colorado and Cluj - Napoca International Biennial of Contemporary Ceramic, Museum of Art Romania.
        </p>
        <p>Folta is a fellow of the New Englang Foundation for the Arts, Massachussetts Cultural Council and Artepreneur as well as a member of the Climate Art Collection in Berlin, Germany.</p>
      </div>
    </main>
    <Footer />
  </Layout>
)
